import React, {useState} from 'react';
import './button.css';
import scan from '../scan.png';

const Button = ({ onClick, label }) => {
    const [imageVisible, setImageVisible] = useState(false);

    const imageSrc = scan;
    const handleClick = () => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            onClick(img);
            setImageVisible(true);
        }
    };

    return (
        <div>
            <div className='button-container'>
                <button className='button' onClick={handleClick}>{label}</button>
            </div>
            <div>
                {imageVisible && <img className='scan' src={scan} alt="Loaded image" />}
            </div>
        </div>
    );
}

export default Button;