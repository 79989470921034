import coffee from './coffee-bean.svg';
import './App.css';
import Button from './components/button.js'

function App() {
    const handleImageLoad = (img) => {
        console.log(img);
    }

  return (
    <div className="App">
      <header className="App-header">
        <img src={coffee} className="App-logo" alt="logo" />
        <Button onClick={handleImageLoad} label='Get started'></Button>
      </header>
    </div>
  );
}

export default App;
